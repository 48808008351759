<template>
	<section id="register">
		<div class="card card_shadow fluid form_card">
			<div class="section">
				<img
          src="/logo.png"
          alt="xanax666 login"
        >

				<input
          v-model="form.email"
          type="email"
          placeholder="e-mail"
        /> <br>

				<input
          v-model="form.name"
          type="text"
          placeholder="name"
        /> <br>

				<input
          v-model="form.password"
          type="password"
          placeholder="password"
        /> <br>

        <input
          v-model="form.password_confirmation"
          type="password"
          placeholder="confirm password"
        /> <br>

        have an account?
				<router-link to="/auth/login">
          login
        </router-link> <br>

				<button
          class="action"
          @click.prevent="this.submitForm()"
        >Register</button>
			</div>
		</div>
	</section>
</template>

<script>
  import AuthService from '@/services/auth.js';
  import Validator from '@/utils/validator.js';
  import SwalAxios from '@/utils/swal-axios.js';
  import '@/assets/css/auth.css';

	export default {
    data: () => {
      return {
        form: {
          email: '',
					name: '',
          password: '',
					password_confirmation: ''
        }
      }
    },
		methods: {
      submitForm() {
        let data = this.form;

        if (
          !Validator.email(data.email) ||
          !Validator.length(data.name, 3) ||
          !Validator.length(data.password, 6) ||
          !Validator.length(data.password_confirmation, 6)
        ) {
          return SwalAxios.toast({
            icon: 'error',
            title: 'invalid register form data.'
          });
        }

        AuthService.register(this, data);
      }
    }
	}
</script>

<style scoped>
	#register {
		transform: translateY(15%);
	}
</style>

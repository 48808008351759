import Swal from 'sweetalert2';
import request from '@/utils/axios';

export default {
  login(app, data) {
    request.post('/auth/login', {
      email: data.email,
      password: data.password,
    })
      .then((response) => {
        let data = response.data;
        
        if (data.status == 'success') {
          app.$store.dispatch('setUser', data.data);

          return Swal.fire({
            title: 'Login success!',
            text: 'you are successfully logged in.',
            icon: 'success',
          }).then(() => {
            window.location.href = '/dashboard';
          });
        }

        return Swal.fire({
          title: 'Login error!',
          text: 'invalid e-mail or password.',
          icon: 'error',
        });
      });
  },
  register(app, data) {
    request.post('/auth/register', {
      email: data.email,
      name: data.name,
      password: data.password,
      password_confirmation: data.password_confirmation
    })
      .then((response) => {
        if (response.data.icon == 'success') {
          response.swal.then(() => {
            app.$router.push('/auth/login');
          });
        }
      });
  }
}
